import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styles from './forms.css';

export const  EditItemForm = () => {
  const { register, handleSubmit, watch, formState: {errors} } = useForm();

  const [result, setResult] = useState();
  const onSubmit = (data) => {
    setResult(JSON.stringify(data));
  }
  // console.log(watch());
  
    return (
       <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor='name'>Назва</label>
          <input {...register("name", {required: `Обов'язкове значення`})} placeholder="Назва" />
          {errors && errors.name && <p>{errors.name.message}</p>}
          
          <label htmlFor='description'>Опис</label>
          <input {...register("description", {required: `Обов'язкове значення`})} placeholder="Опис" />
          {errors && errors.description && <p>{errors.description.message}</p>}
          
          <label htmlFor='manufacturer'>Виробник</label>
          <select {...register("manufacturer")}>
            <option value="">Виберіть...</option>
            <option value="A">Category A</option>
            <option value="B">Category B</option>
          </select>
          <label htmlFor='manufacturer'>Ціна</label>
          <input {...register("price",{valueAsNumber:true, validate: value => parseInt(value) || value==0 ||  'Має бути більше нуля'})} placeholder="Ціна" />
          {errors && errors.price && <p>{errors.price.message}</p>}

          <input type="submit" value='Записати' />
          <p key='p1'>{result}</p>
          

      </form>); 
    
};
