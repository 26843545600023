import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styles from './view_items_list.css';
import {ViewItemIcon} from './view_item_icon.js';


export const ViewItemList = ({items}) => {
    return(
        <div className='container'> {items && items.length ? items.map((item,id) => <ViewItemIcon item={item} key={id}/>) : 'Немає товарів'}
        </div>
    );

}