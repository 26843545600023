import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Rating } from "./rating";
import styles from './view_item_icon.css';

export const ViewItemIcon = ({item}) => {      
//    const pic1 = require(item.img);
    return (
        <div className='icon-container'>
            <div className='leftpart'> 
                <div className='ratedImage'>
                    <img src={item.img} alt={item.name} width="150"/>
                    <Rating rating={item.rating} className='rating'/>
                </div>
                <div className='goods_name'>{item.name}</div>
                <div>Код: {item.partNumber}</div>
            </div>
        </div>
    );
}