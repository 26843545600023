import logo from './logo.svg';
import React, { useState,Fragment } from "react";
import './App.css';
import { EditItemForm } from './edit_item_form';
import { ViewItemList } from './view_item_list';
import {list as items} from './mock_item_list';

function App() {
  return (
  <Fragment>
    <ViewItemList items={items}/>
    <EditItemForm/>
  </Fragment>
  );
}

export default App;
